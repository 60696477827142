import React from 'react';

import Article from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';
import CopyCodeSection from 'components/help/installCodeInstruction';
import Link from 'components/ui/link';

import img3 from 'img/help/shopify/shopify_1.png';
import img4 from 'img/help/shopify/shopify_2.png';
import img5 from 'img/help/shopify/shopify_3.png';
import img6 from 'img/help/shopify/shopify_4.png';
import img7 from 'img/help/shopify/shopify_5.png';
import img8 from 'img/help/shopify/shopify_6.png';
import logo from 'img/help/integrations/shopify.png';

const Content = () => {
  return (
    <div>
      Using LiveSession requires placing a simple JavaScript code into your website. Read this
      article to learn how to install our LiveSession tracking code on Shopify website.
      <br />
      <br />
      <CopyCodeSection />
      <ul className="list-unstyled">
        <li>
          Open your Shopfiy dashboard and <strong>go to Online Store section</strong>.
          <Image src={img3} lazy alt="Shopify integration" title="Go to Online Store section" />
        </li>
        <li>
          Then open <strong>Themes</strong>.
          <Image src={img4} lazy alt="Shopify integration" title="Open Themes" />
        </li>
        <li>
          Find your current theme and click on <strong>Actions</strong>. After that choose{' '}
          <strong>Edit code</strong>.
          <Image src={img5} lazy alt="Shopify integration" title="Edit code" />
        </li>
        <li>
          Open <strong>theme.liquid</strong> file
          <Image src={img6} lazy alt="Shopify integration" title="Open theme.liquid file" />
        </li>
        <li>
          Then please paste copied code into file before <Code inline>{'</head>'}</Code> closing tag
          <Image src={img7} lazy alt="Shopify integration" title="Paste code" />
        </li>
        <li>
          Click on <strong>Save</strong> button.
          <Image src={img8} alt="Shopify integration" title="Save" />
        </li>
        <li>
          After that you&apos;re ready to go! Check if your script works properly using{' '}
          <Link href="/help/how-to-check-if-my-tracking-script-works/"> this guide</Link>.
        </li>
      </ul>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Our tracking code doesn&apos;t work on checkout page, because of Shopify security measures.
      </div>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script/',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = Article(Content);

export const frontmatter = {
  metaTitle: 'How to install LiveSession on Shopify?',
  metaDescription:
    'Quick guide about the LiveSession installation process on store powered by Shopify.',
  logo,
  canonical: '/help/how-install-on-shopify/',
  externalInstallLink: 'https://apps.shopify.com/livesession',
  logoStyle: {
    maxWidth: 160,
  },
};

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Get Started" {...frontmatter} />
);
